import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Field, Formik, ErrorMessage } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { openSubModal } from '../../../redux/lifePlan';
import { generateYearsByMemberAge, ModalName, ValidateField } from '../../../shared/constants/global';
import SelectField from '../../../shared/components/select-field';
import SelectDisplayOption from '../CustomField/SelectDisplayOption';
import InputText from '../CustomField/InputText';
import RadioField from '../../../shared/components/radio-field';
import TextError from '../../../shared/components/error-message';
import { yearUntilOneHundred } from '../../../shared/utils';
import { YupSchema } from '../../../shared/constants/validators';
import { LifePlanService } from '../../../core/services/life-plans';

export default function ClassTab(props) {
  const { t } = useTranslation('saleModal');
  const tGlobal = useTranslation('global').t;

  const dispatch = useDispatch();
  const person_age = useSelector(state => state.life_plan.person_age || 0)

  const emptyOptions = {
    prefectures: {
      id: null,
      code: '',
      name: '都道府県をお選びください'
    },
    city: {
      id: null,
      code: '',
      name: '市区町村をお選びください'
    },
    line: {
      id: null,
      code: '',
      name: '選択してください'
    },
    region: {
      id: null,
      code: '',
      name: '町・丁目をお選びください'
    },
    station: {
      id: null,
      code: '',
      name: '選択してください'
    }
  }

  const formState = useRef();

  const [prefectures, setPrefectures] = useState([emptyOptions.prefectures]);
  const [cities, setCities] = useState([emptyOptions.city]);
  const [lines, setLines] = useState([emptyOptions.line]);
  const [stations, setStations] = useState([emptyOptions.station]);
  const [regions, setRegions] = useState([emptyOptions.region]);

  const [initialValues, setInitialValues] = useState({
    pref_code: '',
    prefecture: '',
    city_code: '',
    city: '',
    region_code: '',
    region: '',
    after_town: '',
    line_code: '',
    line_name: '',
    station_code: '',
    station_name: '',
    building_structure: '',
    built_date: '',
    occupation_area: '',
    floor_located: '',
    purchase_year: '',
    investment_property_loan: 1,
    investment_monthly_repayment: '',
    investment_bonus_repayment: '',
    investment_loan_balance: '',
    investment_repayment_end: 2024,
    operating_costs: '',
    renovation_reserves: '',
    property_tax: '',
    walking_minutes: ''
  });

  const yearUntil100 = useMemo(() => {
    return yearUntilOneHundred(person_age);
  }, [person_age]);

  const yearMapping = {}
  generateYearsByMemberAge().map((y) => yearMapping[y.year] = y.age)

  const renderYearFromNow = (year) => {
    return `${subYear(year)}歳（${year}年）`
  }

  const renderPurchaseYear = (year) => {
    return `${+person_age - new Date().getFullYear() + year}歳（${year}年）`
  }

  const subYear = (year) => {
    const age = yearMapping[year];
    return age;
  }

  const renderBuiltDate = (year) => {
    const year_built = new Date().getFullYear() - year
    if (year_built == 0) {
      return `${year}年`
    } else {
      return `${year}年（築${year_built}年）`
    }
  }

  const dataBuildingStructure = [
    {
      id: "",
      code: "",
      name: "選択してください"
    },
    {
      id: 1,
      code: 1,
      name: "SRC"
    },
    {
      id: 2,
      code: 2,
      name: "RC"
    },
    {
      id: 3,
      code: 3,
      name: "木造"
    },
    {
      id: 4,
      code: 4,
      name: "鉄骨"
    },
    {
      id: 6,
      code: 6,
      name: "軽量鉄骨造"
    },
    {
      id: 5,
      code: 5,
      name: "その他"
    }
  ]

  const handleSubmitFinal = async () => {
    await formState.current.setFieldTouched();
    await formState.current.validateForm()

    if (!formState.current.isValid) {
      console.log('form invalid');
      return;
    }

    const { values } = formState.current;
    let built_date = `${values.built_date}01`
    props.propertyAssessment({
      ...values,
      property_type: 1,
      life_event_flg: 1,
      built_date: built_date
    });
  }

  const renderBuildingStructure = (opt) => {
    return dataBuildingStructure[opt]
  }

  const dataBuiltDate = useMemo(() => {
    const currentYear = new Date().getFullYear();

    let result = []
    for (let i = 1950; i <= currentYear; i++) {
      result.push(i)
    }
    return result;
  }, [])

  const dataPurchaseYear = useMemo(() => {
    const currentYear = new Date().getFullYear();

    let result = []
    for (let i = 1950; i <= currentYear; i++) {
      if (person_age - currentYear + i >= 20) {
        result.push(i)
      }
    }
    return result;
  }, [])

  const handleRemove = () => {
    dispatch(openSubModal(ModalName.LIFEPLAN_CONFIRM_DELETE))
  }

  const validateSchema = new YupSchema(t).classTab;

  const fetchPrefectures = async () => {
    const result = await LifePlanService.getPrefectures();

    if (result.status) {
      setPrefectures([emptyOptions.prefectures, ...result.response]);
      setFormValues({ prefecture: $('#pref_code').find('option:selected').text() })
    }
  }

  const fetchCity = async (data) => {
    const result = await LifePlanService.getCities(data);

    if (result.status) {
      setCities([emptyOptions.city, ...result.response]);
      setFormValues({ city: $('#city_code').find('option:selected').text() })
    }
  }

  const fetchLines = async (data) => {
    const result = await LifePlanService.getLines(data);

    if (result.status) {
      setLines([emptyOptions.line, ...result.response]);
      setFormValues({ line_name: $('#line_code').find('option:selected').text() })
    }
  }

  const fetchRegions = async (data) => {
    const result = await LifePlanService.getRegions(data);

    if (result.status) {
      setRegions([emptyOptions.region, ...result.response]);
      setFormValues({ region: $('#region_code').find('option:selected').text() })
    }
  }

  const fetchStations = async (data) => {
    const result = await LifePlanService.getStations(data);

    if (result.status) {
      setStations([emptyOptions.station, ...result.response]);
      setFormValues({ station_name: $('#station_code').find('option:selected').text() })
    }
  }

  const setFormValues = (values) => {
    Object.keys(values).map(key => {
      formState.current.setFieldValue(key, values[key])
    });
  }

  const handlePrefCodeChange = async (e) => {
    const $this = $(e.target)
    setFormValues({ prefecture: $this.find('option:selected').text() })

    setFormValues({
      city_code: '',
      line_code: '',
      region_code: '',
      line_name: '',
      station_code: '',
      station_name: '',
    });

    setCities([emptyOptions.city]);
    setLines([emptyOptions.line]);
    setRegions([emptyOptions.region]);
    setStations([emptyOptions.station]);

    if ($this.val() === '') return;

    const citiesData = {
      pref_code: $this.find('option:selected').data().id
    }

    const linesData = {
      pref_code: $this.find('option:selected').data().id
    }

    await fetchCity(citiesData);
    await fetchLines(linesData);
  }

  const handleCitiesChange = async (e) => {
    const $this = $(e.target)
    const $form = $this.closest("form")
    const $prefecture = $form.find("select[name=pref_code]")
    setFormValues({ city: $this.find('option:selected').text() })

    setFormValues({
      region_code: ''
    });

    setRegions([emptyOptions.region]);

    if ($this.val() === '') return;

    const regionsData = {
      pref_code: $prefecture.find('option:selected').data().id,
      city_code: $this.find('option:selected').val()
    }

    await fetchRegions(regionsData);
  }

  const handleRegionsChange = (e) => {
    const $this = $(e.target)
    setFormValues({ region: $this.find('option:selected').text() })
  }

  const handleLinesChange = async (e) => {
    const $this = $(e.target)
    const $form = $this.closest("form")
    const $prefecture = $form.find("select[name=pref_code]")
    setFormValues({ line_name: $this.find('option:selected').text() })

    setFormValues({
      station_code: '',
      station_name: ''
    });

    setStations([emptyOptions.station]);

    if ($this.val() === '') return;

    const stationsData = {
      pref_code: $prefecture.val(),
      line_code: $this.val()
    }

    await fetchStations(stationsData);
  }

  const handleStationsChange = (e) => {
    const $this = $(e.target)
    setFormValues({ station_name: $this.find('option:selected').text() })
  }

  const onInvestmentPropertyLoanChange = (investment_property_loan) => {
    if (investment_property_loan === 1) {
      formState.current.setFieldValue("investment_monthly_repayment", '');
      formState.current.setFieldValue("investment_loan_balance", '');
      formState.current.setFieldValue("investment_bonus_repayment", '');
    }
  }

  useEffect(() => {
    const dataEvents = props?.re_sell

    if (dataEvents && dataEvents.property_type === 1) {
      let {
        pref_code,
        city_code,
        region_code,
        after_town,
        line_code,
        station_code,
        building_structure,
        built_date,
        occupation_area,
        floor_located,
        purchase_year,
        walking_minutes,
        investment_property_loan,
        investment_monthly_repayment,
        investment_bonus_repayment,
        investment_loan_balance,
        investment_repayment_end,
        operating_costs,
        renovation_reserves,
        property_tax
      } = dataEvents
      let builtDate = parseInt(built_date.toString().slice(0, 4))

      setFormValues({
        pref_code,
        city_code,
        region_code,
        after_town: after_town || '',
        line_code,
        station_code,
        building_structure,
        built_date: builtDate,
        occupation_area,
        floor_located,
        walking_minutes,
        purchase_year,
        investment_property_loan,
        investment_monthly_repayment: !!investment_monthly_repayment ? +investment_monthly_repayment : '',
        investment_bonus_repayment: !!investment_bonus_repayment ? +investment_bonus_repayment : '',
        investment_loan_balance: !!investment_loan_balance ? +investment_loan_balance : '',
        investment_repayment_end,
        operating_costs: !!operating_costs ? +operating_costs : '',
        renovation_reserves: !!renovation_reserves ? +renovation_reserves : '',
        property_tax: !!property_tax ? +property_tax : ''
      })

      const citiesData = {
        pref_code: pref_code
      }

      const linesData = {
        pref_code: pref_code
      }

      const regionsData = {
        pref_code: pref_code,
        city_code: city_code
      }

      const stationsData = {
        pref_code: pref_code,
        line_code: line_code
      }

      fetchPrefectures();
      fetchCity(citiesData);
      fetchRegions(regionsData);
      fetchLines(linesData);
      fetchStations(stationsData);
    } else {
      fetchPrefectures();
    }
  }, [])

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={validateSchema}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {(formikProps) => {
        const { values, setFieldValue } = formikProps;
        formState.current = formikProps;

        return (
          <Form>
            <dl className="formSty marB40">
              <dt>{t('address')}<br className="pc" /><span className="ico-hiss">{tGlobal('mandatory')}</span></dt>
              <dd>
                <dl className="formTtlSty">
                  <dt>{t('prefecture')}</dt>
                  <dd>
                    <Field name="pref_code" component={SelectDisplayOption} width="wid400" onChange={handlePrefCodeChange} options={prefectures} />
                  </dd>
                  <dt>{t('municipalities')}</dt>
                  <dd>
                    <Field name="city_code" component={SelectDisplayOption} width="wid400" onChange={handleCitiesChange} options={cities} />
                  </dd>
                  <dt>{t('character')}</dt>
                  <dd>
                    <Field name="region_code" component={SelectDisplayOption} width="wid400" onChange={handleRegionsChange} options={regions} />
                  </dd>
                  <dt>{t('addressAfter')}</dt>
                  <dd>
                    <ErrorMessage name="after_town" render={TextError} />
                    <Field type="text" name="after_town" className="wid100p" placeholder="1－1 小田急マンション101号室" />
                  </dd>
                </dl>
              </dd>
              <dt>{t('nearestStation')}<br className="pc" /><span className="ico-hiss">{tGlobal('mandatory')}</span></dt>
              <dd>
                <dl className="formTtlSty">
                  <dt>{t('route')}</dt>
                  <dd>
                    <Field name="line_code" component={SelectDisplayOption} width="wid400" options={lines} onChange={handleLinesChange} />
                  </dd>
                  <dt>{t('stationName')}</dt>
                  <dd>
                    <Field name="station_code" component={SelectDisplayOption} width="wid400" options={stations} onChange={handleStationsChange} />
                  </dd>
                  <dt>{t('stationWalk')}</dt>
                  <dd>
                    <Field type="text" name="walking_minutes" className="wid120" placeholder="10" component={InputText} secondLabel={tGlobal('minutes')} setFieldValue={setFieldValue} />
                  </dd>
                </dl>
              </dd>
              <dt>{t('construction')}<br className="pc" /><span className="ico-hiss">{tGlobal('mandatory')}</span></dt>
              <dd>
                <Field name="building_structure" component={SelectDisplayOption} width="wid400" options={dataBuildingStructure} />
              </dd>
              <dt>{t('age')}<br className="pc" /><span className="ico-hiss">{tGlobal('mandatory')}</span></dt>
              <dd>
                <Field name="built_date" component={SelectField} width="wid400" options={dataBuiltDate} blank="選択してください" display_options={renderBuiltDate} />
              </dd>
              <dt>{t('occupiedArea')}<br className="pc" /><span className="ico-hiss">{tGlobal('mandatory')}</span></dt>
              <dd>
                <Field name="occupation_area" type="text" component={InputText} placeholder="100" className="wid120" secondLabel="&#13217;" setFieldValue={setFieldValue} />
              </dd>
              <dt>{t('locationFloor')}<br className="pc" /><span className="ico-hiss">{tGlobal('mandatory')}</span></dt>
              <dd>
                <Field name="floor_located" type="text" component={InputText} placeholder="6" className="wid120" secondLabel="階" setFieldValue={setFieldValue} />
              </dd>
              <dt>{t('purchaseTime')}<br className="pc" /><span className="ico-hiss">{tGlobal('mandatory')}</span></dt>
              <dd>
                <Field name="purchase_year" component={SelectField} width="wid400" options={dataPurchaseYear} blank="選択してください" display_options={renderPurchaseYear} />
              </dd>
              <dt>{t('loan')}</dt>
              <dd>
                <div>
                  <div id="checkShow3">
                    <Field name="investment_property_loan" options={[1, 2]} labels={t('loanOption', { returnObjects: true })} component={RadioField} onChange={() => onInvestmentPropertyLoanChange(values.investment_property_loan)} />
                  </div>
                  <ul id="checkShow3-item" className={`checkShowItem itemRowList ${values.investment_property_loan === 2 && 'hiddenItem'}`}>
                    <li style={{display: 'block'}}>
                      <Field className="wid80 toggle-item" decimal={1} type="text" component={InputText} name="investment_monthly_repayment" label={t('options.0')} secondLabel={tGlobal('tenThousandYen')} disabled={values.investment_property_loan === 2} setFieldValue={setFieldValue} />
                    </li>
                    <li style={{display: 'block'}}>
                      <Field className="wid80 toggle-item" type="text" component={InputText} name="investment_loan_balance" label={t('options.1')} secondLabel={tGlobal('tenThousandYen')} disabled={values.investment_property_loan === 2} setFieldValue={setFieldValue} />
                    </li>
                    <li style={{display: 'block'}}>
                      <Field className="wid80 toggle-item" type="text" component={InputText} name="investment_bonus_repayment" label={t('options.2')} secondLabel={tGlobal('tenThousandYen') + '/1回分'} disabled={values.investment_property_loan === 2} setFieldValue={setFieldValue} />
                    </li>
                    <li style={{display: 'block'}}>
                      <div>
                        <span>{t('options.3')}</span>
                        <span className="itemRowListInput">
                          <div className="selectStyBox">
                            <Field name="investment_repayment_end" className="wid155" options={yearUntil100} component={SelectField} display_options={renderYearFromNow} disabled={values.investment_property_loan === 2} />
                          </div>
                          <span className="formUnit">まで</span>
                        </span>
                      </div>
                    </li>
                  </ul>
                </div>
              </dd>
              <dt>{t('operatingCost')}</dt>
              <dd className="noFlex">
                <div className="marB1em dF aisC ranningCost">
                  <span className="fontWB wid160 spMarB05em">{t('operatingCost')}</span>
                  <div>
                    <Field className="wid80 hit36 a" decimal={1} type="text" component={InputText} name="operating_costs" secondLabel={tGlobal('tenThousandYen') + '/月間'} setFieldValue={setFieldValue} />
                  </div>
                  <span className="marL16 font14">{t('costNotice')}</span>
                </div>
                <dl className="formTtlSty nowrap">
                  <dt>{t('reverseFund')}</dt>
                  <dd>
                    <Field className="wid80 hit36" decimal={1} type="text" component={InputText} name="renovation_reserves" secondLabel={tGlobal('tenThousandYen') + '/月間'} setFieldValue={setFieldValue} />
                  </dd>
                  <dt>{t('propertyTax')}</dt>
                  <dd>
                    <Field className="wid80 hit36 toggle-item" decimal={1} type="text" component={InputText} name="property_tax" secondLabel={tGlobal('tenThousandYen') + '/月間'} setFieldValue={setFieldValue} />
                  </dd>
                </dl>
              </dd>
            </dl>
            <div className="popupBtnBox">
              <ul className="btnList col2">
                <li><button type="button" className="btnSty-back" onClick={props.handleClose}>キャンセル</button></li>
                <li><button type="submit" className="btnSty-send" onClick={handleSubmitFinal}>次へ</button></li>
                { props && props.uuid && <li className="btnSty-delete"><button type="button" onClick={handleRemove}>{tGlobal('delete')}</button></li> }
              </ul>
            </div>
          </Form>
        )
      }}
    </Formik>)
}
