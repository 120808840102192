import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from 'react-i18next';
import { Form, Field, Formik } from 'formik';
import { YupSchema } from '../../../shared/constants/chatbot-validators';
import SelectDisplayOption from './CustomField/SelectDisplayOption';
import TextInput from './CustomField/TextInput';
import { LifePlanService } from '../../../core/services/life-plans';

export default function AddressForm(props) {
  const { t } = useTranslation('saleModal');
  const validateSchema = new YupSchema(t).addressStep;
  const formState = useRef();
  const initialValues = props.initialValues

  const emptyOptions = {
    prefectures: {
      id: null,
      code: '',
      name: '選択してください'
    },
    city: {
      id: null,
      code: '',
      name: '選択してください'
    },
    region: {
      id: null,
      code: '',
      name: '選択してください'
    }
  }

  const setFormValues = (values) => {
    Object.keys(values).map(key => {
      formState.current.setFieldValue(key, values[key])
    });
  }

  const [prefectures, setPrefectures] = useState([emptyOptions.prefectures]);
  const [cities, setCities] = useState([emptyOptions.city]);
  const [regions, setRegions] = useState([emptyOptions.region]);

  const fetchPrefectures = async () => {
    $("#preloadAddress").fadeIn();
    const result = await LifePlanService.getPrefectures();

    if (result.status) {
      setPrefectures([emptyOptions.prefectures, ...result.response]);
      setFormValues({ prefecture: $('#pref_code').find('option:selected').text() })
    }
    $("#preloadAddress").fadeOut();
  }

  const fetchCity = async (data) => {
    $("#preloadAddress").fadeIn();
    const result = await LifePlanService.getCities(data);

    if (result.status) {
      setCities([emptyOptions.city, ...result.response]);
      setFormValues({ city: $('#city_code').find('option:selected').text() })
    }
    $("#preloadAddress").fadeOut();
  }

  const fetchRegions = async (data) => {
    $("#preloadAddress").fadeIn();
    const result = await LifePlanService.getRegions(data);

    if (result.status) {
      setRegions([emptyOptions.region, ...result.response]);
      setFormValues({ region: $('#region_code').find('option:selected').text() })
    }
    $("#preloadAddress").fadeOut();
  }

  const handlePrefCodeChange = async (e) => {
    const $this = $(e.target)
    setFormValues({ prefecture: $this.find('option:selected').text() })

    setFormValues({
      city_code: '',
      region_code: '',
    });

    setCities([emptyOptions.city]);
    setRegions([emptyOptions.region]);

    if ($this.val() === '') return;

    const citiesData = {
      pref_code: $this.find('option:selected').data().id
    }

    await fetchCity(citiesData);
  }

  const handleCitiesChange = async (e) => {
    const $this = $(e.target)
    const $form = $this.closest("form")
    const $prefecture = $form.find("select[name=pref_code]")
    setFormValues({ city: $this.find('option:selected').text() })

    setFormValues({
      region_code: ''
    });

    setRegions([emptyOptions.region]);

    if ($this.val() === '') return;

    const regionsData = {
      pref_code: $prefecture.find('option:selected').data().id,
      city_code: $this.find('option:selected').val()
    }

    await fetchRegions(regionsData);
  }

  const handleRegionsChange = (e) => {
    const $this = $(e.target)
    setFormValues({ region: $this.find('option:selected').text() })
  }

  useEffect(() => {
    const dataEvents = initialValues
    let {
      pref_code,
      city_code,
    } = dataEvents

    const citiesData = {
      pref_code: pref_code
    }

    const regionsData = {
      pref_code: pref_code,
      city_code: city_code
    }

    fetchPrefectures();
    fetchCity(citiesData);
    fetchRegions(regionsData);
  }, [])

  const handleTriggerNextStep = async(values) => {
    await formState.current.setFieldTouched();
    await formState.current.validateForm()

    if (!formState.current.isValid) return;

    const storage = JSON.parse(window.lsSession.getItem(`chatbot-${props.formType}`))
    const newObj = {...storage, ...values}
    const storageStr = JSON.stringify(newObj)
    window.lsSession.setItem(`chatbot-${props.formType}`, storageStr)
    props.triggerNextStep({ trigger: props.trigger, value: values, replaceAll: props.step.id })
  }

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={validateSchema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={handleTriggerNextStep}
    >
      {(formikProps) => {
        const { handleSubmit } = formikProps;
        formState.current = formikProps;

        return (
          <Form onSubmit={handleSubmit}>
            {/* Component Preloader */}
            <div id="preloadAddress" className="preload">
              <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
            </div>

            <div className="selectOption">
              <div className="titleSelect">都道府県</div>
              <Field name="pref_code" component={SelectDisplayOption} onChange={handlePrefCodeChange} options={prefectures} />
            </div>
            <div className="selectOption">
              <div className="titleSelect">市区町村</div>
              <Field name="city_code" component={SelectDisplayOption} onChange={handleCitiesChange} options={cities} />
            </div>
            <div className="selectOption">
              <div className="titleSelect">町・丁目</div>
              <Field name="region_code" component={SelectDisplayOption} onChange={handleRegionsChange} options={regions} format="long" />
            </div>
            <div className="selectOption">
              <div className="titleSelect">丁目より後の住所</div>
              <Field name="after_town" component={TextInput} type="text" placeholder="入力してください。" />
            </div>
            <button type="submit" className="btnSubmit">
              決定
            </button>
          </Form>
        )
      }}
    </Formik>
  )
}
